// import { useState } from "react";
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Row, Col, Image } from 'react-bootstrap';
import DiamondText from '../Components/DiamondText';
import Jobs from "../Components/Jobs";
// import PhotoAlbum from "react-photo-album";
// import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

export const CareerPage = () => {

    const { t } = useTranslation();
    // const [index, setIndex] = useState(-1);

    return (
        <>
            <Helmet>
                <title>{t('career.title')}</title>
                <meta name="description" content="Career Page for Bailänder" />
            </Helmet>

            <Container>
                <Row className="justify-content-md-center" >
                    <Col md={12} >
                        <Image className='image-gruppe-top' style={{ width: '100%', marginTop: 80 }} src='../img/title-images/bailaender_gruppe_v2.jpg' fluid alt='team-title'></Image>
                    </Col>
                    <Col md={12} lg={8}>
                        <h1 className='main-page-heading-subtitle' >{t('career.subtitle')}</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 100, marginTop: 100 }}>
                    <Col md={12} lg={{ span: 6, offset: 3 }}>
                        <p style={{ textAlign: 'center' }} className='text-services-pages'>{t('career.text1')}</p>
                    </Col>
                    {/* <Col md={12} lg={{ span: 5, offset: 1 }}>
                        <p className='text-services-pages'>{t('career.text1')}</p>
                    </Col> */}
                    {/*                     <Col md={12} lg={5}>
                        <p className='text-services-pages'></p>
                    </Col> */}
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <h1 style={{ marginBottom: 50 }}>{t('career.benefits')}</h1>
                    </Col>
                </Row>

                <Row>
                    <DiamondText title={t('career.diamondHeadline1')} description={t('career.diamondText1')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline2')} description={t('career.diamondText2')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline3')} description={t('career.diamondText3')}></DiamondText>

                    <DiamondText title={t('career.diamondHeadline4')} description={t('career.diamondText4')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline5')} description={t('career.diamondText5')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline6')} description={t('career.diamondText6')}></DiamondText>

                    <DiamondText title={t('career.diamondHeadline7')} description={t('career.diamondText7')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline8')} description={t('career.diamondText8')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline9')} description={t('career.diamondText9')}></DiamondText>

                    <DiamondText title={t('career.diamondHeadline10')} description={t('career.diamondText10')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline11')} description={t('career.diamondText11')}></DiamondText>
                    <DiamondText title={t('career.diamondHeadline12')} description={t('career.diamondText12')}></DiamondText>

                    <DiamondText title={t('career.diamondHeadline13')} description={t('career.diamondText13')}></DiamondText>
                    <Col xs={12} style={{ marginBottom: 150, textAlign: "center" }}>
                        <h5 style={{ fontWeight: "bold" }} >{t('career.more')}</h5>

                        <Link to='/bewerbung' onClick={() => {
                            ReactPixel.track("Online Bewerbung gestartet", true)
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'auto',
                            })
                        }}>
                            <button variant="dark" className="button-orange">Jetzt einfach online Bewerben</button>
                        </Link>

                    </Col>
                </Row>

            </Container>

            <Jobs></Jobs>


            {/* Gallery */}

            {/*             <Container>

                <Row>
                    <Col xs={12} md={12}>
                        <h1 style={{ marginTop: 150, marginBottom: 100, textAlign: 'center' }}>{t('career.insights')}</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 150 }}>
                    <PhotoAlbum
                        photos={[
                            {
                                src: "/img/gallery/picture (7).jpg",
                                width: 286,
                                height: 180
                            },
                            {
                                src: "/img/gallery/picture (8).jpg",
                                width: 482,
                                height: 251
                            },
                            {
                                src: "/img/gallery/picture (6).jpg",
                                width: 188,
                                height: 251
                            },
                            {
                                src: "/img/gallery/picture (9).jpg",
                                width: 189,
                                height: 251
                            },
                            {
                                src: "/img/gallery/picture (4).jpg",
                                width: 286,
                                height: 180
                            },
                            {
                                src: "/img/gallery/picture (5).jpg",
                                width: 286,
                                height: 180
                            },
                            {
                                src: "/img/gallery/picture (10).jpg",
                                width: 287,
                                height: 439
                            },
                            {
                                src: "/img/gallery/picture (1).jpg",
                                width: 286,
                                height: 180
                            },
                            {
                                src: "/img/gallery/picture (11).jpg",
                                width: 286,
                                height: 251
                            },
                            {
                                src: "/img/gallery/picture (3).jpg",
                                width: 287,
                                height: 321
                            },
                            {
                                src: "/img/gallery/picture (2).jpg",
                                width: 286,
                                height: 180
                            }
                        ]}
                        columns={(containerWidth) => {
                            if (containerWidth < 600) return 2;
                            if (containerWidth < 800) return 3;
                            return 4;
                        }}
                        layout="masonry"
                        onClick={({ index }) => setIndex(index)} />

                    <Lightbox
                        slides={[
                            {
                                src: "/img/gallery/picture (7).jpg"
                            },
                            {
                                src: "/img/gallery/picture (8).jpg"
                            },
                            {
                                src: "/img/gallery/picture (6).jpg"
                            },
                            {
                                src: "/img/gallery/picture (9).jpg"
                            },
                            {
                                src: "/img/gallery/picture (4).jpg"
                            },
                            {
                                src: "/img/gallery/picture (5).jpg"
                            },
                            {
                                src: "/img/gallery/picture (10).jpg"
                            },
                            {
                                src: "/img/gallery/picture (1).jpg"
                            },
                            {
                                src: "/img/gallery/picture (11).jpg"
                            },
                            {
                                src: "/img/gallery/picture (3).jpg"
                            },
                            {
                                src: "/img/gallery/picture (2).jpg"
                            }
                        ]}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                    />

                </Row>

            </Container>
            <Container className="career-CallToAction-Container">
                <div className='career-CallToAction'>
                    <h1 style={{ color: "white", textAlign: "center", marginBottom: 50 }}>Sounds like a perfect match for you?</h1>
                    <p style={{ color: "white", textAlign: "center", marginBottom: 50 }}>
                        {t('career.callToAction1')}<br></br>
                        {t('career.callToAction2')}
                    </p>
                    <button variant="dark" className="career-button">{t('career.application')}</button>


                </div>
            </Container> */}
            <Container style={{ marginBottom: 0 }}>


                {/* <Row>
                    <Col xs={12} md={12}>
                        <h1 style={{ marginBottom: 10 }}>{t('career.bottom1')}</h1>
                        <h2 style={{ color: "black" }}>{t('career.bottom2')}</h2>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center', marginTop: 100, marginBottom: 150 }}>
                    <Col xs={12} md={{ offset: 2, span: 8 }}>
                        <svg className='footer-social-certificate' width="170" height="212" viewBox="0 0 170 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="170" height="212" fill="#D9D9D9" />
                        </svg>

                        <svg className='footer-social-certificate' width="170" height="212" viewBox="0 0 170 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="170" height="212" fill="#D9D9D9" />
                        </svg>

                        <svg className='footer-social-certificate' width="170" height="212" viewBox="0 0 170 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="170" height="212" fill="#D9D9D9" />
                        </svg>

                        <svg className='footer-social-certificate' width="170" height="212" viewBox="0 0 170 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="170" height="212" fill="#D9D9D9" />
                        </svg>
                    </Col>

                </Row> */}

            </Container>
        </>
    )
}

export default CareerPage;